/* Styles for the header */
header {
    background: none; /* Remove background properties */
    display: flex;
    justify-content: center;
    object-fit: cover;
    align-items: center;
    position: relative;
    overflow: hidden; /* Hide overflowing content */
  }
  
  /* Style the media container that holds both the image and video */
  .media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh; /* Adjust the height based on your preference */
    overflow: hidden;
  }
  
  /* Style the background image */
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; /* Place the image below the video */
    overflow: hidden;
  }
  
  /* Style the video to be initially hidden */
  video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the container */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; /* Place the video above the image */
    overflow: hidden;
  }
  
  /* Default styles for the header content */
  header .cta {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    
    /* H1 */
    font-family: "Archive";
    font-size: 60px;
    font-style: thin;
    font-weight: 200;
    padding: 0 15%;
    line-height: 1.15;
    letter-spacing: 16px;
    position: relative; /* Add this line */
    z-index: 3; 
    text-shadow: #00000028 3px 3px 7px;
  }

  /* Style the logo container at the top */
.logo-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: 30px;

    height: 30px; /* Adjust the height based on your logo's size */
   
    z-index: 4; /* Ensure the logo is on top */
  }
  
  /* Style the logo */
  .logo {
    height: 26px;
  }
  .navbox {
    position: fixed;
    top: 2vh;
    right: 3vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 40.999px;
    flex-direction: column;
}





 
  
  /* Styles for small screens (e.g., screens less than 768px wide) */
  @media screen and (max-width: 768px) {
    header .cta {
        font-size: 40px;
        letter-spacing: 10px;
        font-weight: 200 !important;
        font-style: normal;
    }
    header .background-image {
        display: none;
    }
    
    /* Adjust styles for video container on small screens */
    header .video-container {
        padding-top: 0; /* Remove aspect ratio padding for small screens */
        height: 100vh; /* Full height of the screen */
    }

    .logo-container {
        left: 16px; /* Adjust the left spacing as needed */
        top: 16px;
    }

    .logo {
        height: 1.25em;

    }

    header video {
        max-width: 100%; /* Ensure video doesn't exceed the viewport width */
        height: 100%; /* Full height of the video container */
        object-fit: cover; /* Adjust object-fit based on your preference */
    }

}
  