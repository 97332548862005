:root {
  --green-leaf-color: #475C45;
  --green-dark-color: #272C26;
  --blue-pastel-color: #929CA9;
  --yellow-pale-color: #F6E26D;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


/* Define your font-face rules here */
@font-face {
  font-family: "Archive";
  src: url("../public/fonts/Archivo-ExtraLight.woff2") format("woff2"),
    url("../public/fonts/Archivo-ExtraLight.woff") format("woff"),
    url("../public/fonts/Archivo-ExtraLight.ttf") format("ttf");
  font-style: normal;
  font-weight: 400;
  /* letter-spacing: 33.6px; */
}

@font-face {
  font-family: "Archive";
  src: url("../public/fonts/Archivo-SemiBold.woff2") format("woff2"),
    url("../public/fonts/Archivo-SemiBold.woff") format("woff"),
    url("../public/fonts/Archivo-SemiBold.ttf") format("ttf");
  font-style: bold;
  font-weight: 600;
  /* letter-spacing: 33.6px; */
}

@font-face {
  font-family: "Archive";
  src: url("../public/fonts/Archivo-ExtraLightItalic.woff2") format("woff2"),
    url("../public/fonts/Archivo-ExtraLightItalic.woff") format("woff"),
    url("../public/fonts/Archivo-ExtraLightItalic.ttf") format("ttf");
  font-style: italic;
  font-weight: 400;
  /* letter-spacing: 33.6px; */
}

@font-face {
  font-family: "SpaceGrotesk";
  src: url("../public/fonts/SpaceGrotesk-Light.woff2") format("woff2"),
    url("../public/fonts/SpaceGrotesk-Light.woff") format("woff"),
    url("../public/fonts/SpaceGrotesk-Light.ttf") format("ttf");
  font-style: normal;
  font-weight: 300;
  letter-spacing: 33.6px;
}

@font-face {
  font-family: "SpaceGrotesk";
  src: url("../public/fonts/SpaceGrotesk-Bold.woff2") format("woff2"),
    url("../public/fonts/SpaceGrotesk-Bold.woff") format("woff"),
    url("../public/fonts/SpaceGrotesk-Bold.ttf") format("ttf");
  font-style: bold;
  font-weight: 400;
  letter-spacing: 33.6px;
}


/* Apply the font-family to the body */
body {
  margin: 0;
  font-family: "Archive";
}

.wrapper {
  max-width: 1024px;
  margin: 0;
}

a {
  text-decoration: none;
}

h1 {
  font-weight: 200;
}

h2 {
  font-family: "Archive";
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  /* line-height: 1.2; */
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 26px;
    line-height: 1.8rem;
  }
}