.info-wrapper {
    /* max-width: 1200px; */
    display: flex;
    flex-direction: row;
    padding: 5rem 7rem;
}

.info-image {
    width: 460px;
    height: 300px;
    background-image: url('../../assets/GreenShotOfWaterfall.jpg');
    background-size: cover;
    /* border-radius: 4px; */
}

.info-content {
    max-width: 650px;
    padding-left: 3.5rem;
}

.info-content p {
    font-size: 17px;
}

.info-content h2 {
    margin-top: 0;
    margin-bottom: 0.75rem;
    color: #121212;
    letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
    .info-wrapper {
        flex-direction: column;
        padding: 3rem 2.25rem 2.25rem 2.25rem;
    }

    .info-image {
        width: 100%;
        height: 55vw;
        margin-bottom: 1.25rem;
    }

    .info-content {
        padding-left: 0;
    }
}