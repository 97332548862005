footer {
    
    max-height: 50vh;
    height: 30vh;
    background: url('../../assets/FooterGreenRectangle.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
}

.follow-links {
  margin-left: 10px;
  margin-bottom: 1vh;

}

.icon-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;

}

* p2{
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-size: 18px;
}

footer .cta { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: bottom; /* Center text within the container */
    color: #ffffff4f;
    top: 35vh;
    /* H1 */
    font-family: "Archive";
    font-size: 16px; /* Adjust the font size to your preference */
    font-style: normal;
    font-weight: 100;
    line-height: 0.2; /* Use a unitless value for better control */
    letter-spacing: 2px; /* Adjust the letter spacing to your preference */
}


  /* MOTHER LOGO footer non-mobile default */
  .footerlogo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: reverse;
    height: 2vh;
    padding: 4vh;
    
  }

  .mothersvg{
    height: 3.333vh;
    margin-bottom: 1vh;
  }

  @media screen and (max-width: 768px) {
    .mothersvg{
      height: 2vh;
    }

    /* MOTHER LOGO footer mobile only */
    .footerlogo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3em;
      margin-bottom: 1.5em;
      margin-top: 40px;
      padding: 0;

    } 

    /* ICONS footer mobile only */
    .follow-links {
      margin-left: 10px;
      margin-bottom: 20px;
    }

    .headline {
      font-family: "SpaceGrotesk";
      font-style: normal;
      font-size: 16px;
      margin-bottom: 1.5em;
    }

  }

  