.waitlist {
    /* background: url('../../assets/GreenShotOfWaterfall.jpg'); */
    background-color: #2e492c;
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    margin: 0.5rem 7rem 5rem 7rem;
}


.waitlist .cta { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center text within the container */
    color: #ffffff;
    text-align: left;
    
    /* H1 */

    @media screen and (max-width: 768px) {
      letter-spacing: 4px;
      font-size: 48px;
      margin-bottom: 0.5em;
    }
}

.waitlist .cta h2 {
  margin-top: 2.75rem;
  letter-spacing: 1px;
}

.waitlist .disclaimer {
  text-align: center;
  margin-top: 3.5rem;
  color: #ffffff81;
  font-size: 14px;
  padding: 0 1.25rem;
}

 /* Styles for small screens (e.g., screens less than 768px wide) */
@media screen and (max-width: 768px) {

    .waitlist {
      margin: 1em 0 0 0;
      padding: 2rem 2.25rem 0 2.25rem;
    }
    .waitlist .cta {
        text-align: center;
    }

    .waitlist .cta h2 {
      margin-top: 1.25em;
    }

    .waitlist .mobile-icon {

        margin-left: 1.4em;
        display: flex;
        margin-right: 2em;
    }
}


form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: auto 1.5em;
    align-items: center;
  }
}

input[name="email"] {

    outline: none;
    border: none;
    background-color: white;
    border-radius: 20px;
    font-size: 18px;
    width: 25em;
    padding: 0.5em 2em 0.5em 2em;
    font-family: "SpaceGrotesk";
    font-style: italic;
    font-weight: 200;
  
    &::placeholder {
  
      color: #000000b0;
    }
}

button[name="join-button"] {

    color: white;
    text-align: center;

    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 98.3%; /* 17.694px */
    border-radius: 40px;
    padding: 0.65em 5em;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    font-family: "Archive";
    font-style: bold;
    background-color: transparent;
    border: 3px solid white;
    margin-top: 1.5rem;
    letter-spacing: 1px;

  &:hover {
    border-color: var(--yellow-pale-color);
    background-color: var(--yellow-pale-color);
    color: black;
  }
}


@media screen and (max-width: 768px) {

  input[name="email"] {

    display: flex;
    justify-content: center;
    font-size: 17px; 
    width: 100%;
    padding: 0.555em 0em 0.6em 1.444em;
  }

  button[name="join-button"] {
    margin-top: 1.125em;
    display: flex;
    padding: 0.65em 3.5em;
  }
}
