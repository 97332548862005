
.glass-modal {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Replace the background and backdrop-filter properties */
    background: none; /* Remove the white background */
    backdrop-filter: blur(10px); /* Apply blur effect to the content behind */
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
  }
  
  .glass-modal.active {
    display: flex;
  }
  
  .modal-content {
    background: rgba(255, 255, 255, 0);
    padding: 100px;
    border-radius: 60px;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.42);
    color: aliceblue;
    font-size: 50px;
  }

  * h5{

    font-size: 16px;
    margin-bottom: -7vh;

  }
  
  .close {
    display: flex;
    position: relative;
    justify-content: right;
    top: -10vh;
    right: -7vh;
    cursor: pointer;
    &:hover{
        color:#f8b9a1 ;
    }
  }
  
  .emoji {
    margin-top: -20vh;
    margin-bottom: -5vh;
    font-size: 88px;
  }
  
  @media screen and (max-width: 768px) {

    .modal-content {
      background: rgba(255, 255, 255, 0);
      padding: 100px;
      margin-left: 10vh;
      margin-right: 10vh;
      border-radius: 60px;
      text-align: center;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.42);
      color: aliceblue;
      font-size: 50px;
    }

    
  }


  